export default [
  {
    name: "Twitter",
    description: "Follow us to stay updated and share your experience.",
    icon: "Twitter.png",
    buttonText: "Say hi!",
    url: "https://twitter.com/VisualEyesHQ"
  },
  {
    name: "Dribbble",
    description: "Get to know our brand guidelines and inspire us.",
    icon: "Dribbble.png",
    buttonText: "Get inspiration",
    url: "https://dribbble.com/VisualEyesHQ"
  },
  {
    name: "instagram",
    description:
      "Tips and tricks for VisualEyes and our daily design routines.",
    icon: "Instagram.png",
    buttonText: "FOLLOW us",
    url: "https://www.instagram.com/visualeyes_hq/"
  },
  {
    name: "spectrum",
    description: "Evolve the future of design by sharing your thoughts.",
    icon: "Spectrum.png",
    buttonText: "Join Now",
    url: "https://spectrum.chat/visualeyes-designers?tab=posts"
  }
];
