import React from "react";
import styled from "styled-components";
import Image from "../../components/Image";

const MAX_WIDTH = "800px";

export const TileList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > a {
    width: 100%;
    margin-bottom: 2rem;
  }
  & > a:last-child {
    margin-bottom: 0;
  }
`;

export const Tile = styled.div`
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  &:hover {
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
  }

  width: 100%;
  padding: 2rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    width: 160px;
  }

  @media only screen and (max-width: ${MAX_WIDTH}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      width: 100%;
    }
  }
`;

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > div {
    margin-right: 2rem;
  }
  & > div:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: ${MAX_WIDTH}) {
    flex-direction: column;
    margin-bottom: 2rem;
    & > div {
      margin: 0;
      margin-bottom: 2rem;
    }
    & > div:last-child {
      margin: 0;
      margin-bottom: 0;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: ${MAX_WIDTH}) {
    align-items: center;
  }
`;
const Icon = styled.div`
  width: 100px;
  height: 100px;

  @media only screen and (max-width: ${MAX_WIDTH}) {
    width: 60px;
    height: 60px;
  }
`;
const Title = styled.h2`
  margin: 0;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  color: #525f7f;

  @media only screen and (max-width: ${MAX_WIDTH}) {
    text-align: center;
  }
`;
const Description = styled.p`
  margin: 0;
  color: #aaa;

  @media only screen and (max-width: ${MAX_WIDTH}) {
    text-align: center;
  }
`;

export const SocialMedia = ({ icon, name, description }) => {
  return (
    <SocialMediaContainer>
      <Icon>
        <Image filename={icon} alt={icon + " logo"} />
      </Icon>
      <TextContainer>
        <Title>{name}</Title>
        <Description>{description}</Description>
      </TextContainer>
    </SocialMediaContainer>
  );
};
