import React from "react";
import Community from "../containers/Community";
import MainLayout from "../components/MainLayout";

export default () => {
  return (
    <MainLayout
      hasGradient
      title="Community"
      pathname="/community"
      description="The future of VisualEyes is shaping with the help of people like you. Join our social networks to build the future of design together."
    >
      <Community />
    </MainLayout>
  );
};
