import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Button from "reusecore/src/elements/Button";
import SectionLayout from "../../components/SectionLayout";
import data from "./data";

import { TileList, Tile, SocialMedia } from "./Community.style";

const Community = ({ row, col, btnStyle }) => {
  return (
    <SectionLayout
      sectionId="service_section"
      title="Community"
      description="The future of VisualEyes is shaping with the help of people like you. Join our social networks to build the future of design together."
    >
      {/* <Box className="row" {...row} justifyContent="center">
        <Box className="col" {...col} flexDirection="column" flexBox={true}>
          <Illustration height={300} style={{ margin: "0 auto 2rem auto" }} />
        </Box>
      </Box> */}
      <Box className="row" {...row} justifyContent="center">
        <Box className="col" {...col} flexDirection="column" flexBox={true}>
          <TileList>
            {data.map(socialMedia => (
              <a href={socialMedia.url} target="_blank" rel="noreferrer">
                <Tile>
                  <SocialMedia
                    name={socialMedia.name}
                    description={socialMedia.description}
                    icon={socialMedia.icon}
                  />

                  <a href={socialMedia.url} target="_blank" rel="noreferrer">
                    <Button
                      className="outlined"
                      title={socialMedia.buttonText}
                      {...btnStyle}
                    />
                  </a>
                </Tile>
              </a>
            ))}
          </TileList>
        </Box>
      </Box>
    </SectionLayout>
  );
};

// Community style props
Community.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object
};

// Community default style
Community.defaultProps = {
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: "wrap"
  },
  // feature col default style
  col: {
    width: [3 / 4, 1, 1, 3 / 4],
    color: "#525f7f"
  },
  btnStyle: {
    fontSize: ["13px", "16px"],
    fontWeight: "500",
    colors: "primaryWithBg",
    width: "100%"
  }
};

export default Community;
